/**
 * @file ReportsForm.tsx component for the form to request a CSV report
 * @author Christian Vasold
 * @exports React.Component
 */
import { useState, useRef } from "react";
import { Grid, Button, FormHelperText, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CSVLink } from "react-csv";
import { Data, Headers } from "react-csv/lib/core";
import useStyles from "./style";
import RadioSelect from "../../common/templates/forms/RadioSelect/RadioSelect";
import { Formik, Form, FormikValues, Field } from "formik";
import { validationSchema } from "./validationSchema";
import reportService, { ReportData } from "../../../services/reportService";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import { extractErrorMessage } from "../../../utils/utils";

/**
 * Properties to pass to the component
 */
interface Props {
    partnerId?: string;
    brandId?: string;
}

/**
 * Parameters for the backend call
 */
export type ApiCallParams = {
    from: string;
    to: string;
    type: string;
    levelOfDetail: ReportLevelOfDetail;
    callAnalytics: boolean;
    id?: string;
}

/** 
 * Specifies the amount of information in the report
 */
enum ReportLevelOfDetail {
    /** 
     * The report will contain only a single line (total count) per day. Or a single line at all for the aggregate report
     */
    GLOBAL = "global",
    /** 
     * The report will contain the total number of calls only on the "partner" level
     */
    PARTNERS = "partners",
    /** 
     * The report will contain the total number of calls on the "partner" and "brand" levels
     */
    BRANDS = "brands",
    /** 
     * The report will contain the total number of calls on the "partner", "brand" and "campaign" levels
     */
    CAMPAIGNS = "campaigns",
    /** 
     * The report will contain an additional "phone number" level compared to the Campaigns report
     */
    NUMBERS = "numbers"
}

/**
 * Reports form component. Shows a UI to select start date, 
 * end date and type of the report and a button to fetch 
 * the data from the server which in turn will generate a link to a CSV file to download.
 */
export default function ReportsForm(props: Props) {

    const getAllReports = (vals: ApiCallParams) => reportService.getAll(vals);
    const getPartnerReports = (vals: ApiCallParams) => reportService.getByPartnerId(vals);
    const getBrandReports = (vals: ApiCallParams) => reportService.getByBrandId(vals);

    const classes = useStyles();

    /** A reference to the CSVLink component, which we use to simulate a click onto it */
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    /** Holds the data that will go into the CSV */
    const [data, setData] = useState<Data>([]);
    /** Holds the headers that will go into the CSV */
    const [headers, setHeaders] = useState<Headers>([]);
    /** Holds the name of the CSV file that will be downloaded */
    const [csvFileName, setCsvFileName] = useState<string>("");
    /** Error information for the snackback */
    const [error, setError] = useState(false);
    /** The error message for the snackback */
    const [errorMsg, setErrorMsg] = useState<string>("");

    let reportLevelOptions: { label: string, value: ReportLevelOfDetail}[] = [];
    if (!props.brandId) {
      if (!props.partnerId) {
        reportLevelOptions.push({ label: "Global", value: ReportLevelOfDetail.GLOBAL });
      }
      reportLevelOptions.push({ label: "Partners", value: ReportLevelOfDetail.PARTNERS });
    }
    reportLevelOptions.push({ label: "Brands", value: ReportLevelOfDetail.BRANDS });
    reportLevelOptions.push({ label: "Campaigns", value: ReportLevelOfDetail.CAMPAIGNS });
    reportLevelOptions.push({ label: "Numbers", value: ReportLevelOfDetail.NUMBERS });

    /** 
     * Converts a date into an ISO date string like 2023-06-23,
     * using the local time of the date.
    */
    function toIsoDate(date: Date): string {
        return date.getFullYear() + "-"
            + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
            + ("0" + date.getDate()).slice(-2);
    }

    /**
     * Handles submission of the form to generate the report. Calls the backend, generates the CSV file and makes the browser download it.
     */
    // TODO we could/should move this to the page using the component, along with the mutation functions
    async function handleRequestReport(values: FormikValues) {

        // REST parameters for our backend call
        const params: ApiCallParams = {
            from: "",
            to: "",
            type: values.type,
            levelOfDetail: values.levelOfDetail,
            callAnalytics: values.callAnalytics
        };

        // From the datepicker we get a unix timestamp. However, the user has selected the day that they want the report for in their local timezone.
        // So we need to get the local day/month/year from the date, pad with zeroes and format into an ISO string
        params.from = toIsoDate(new Date(values.from))
        params.to = toIsoDate(new Date(values.to));

        let backendCall: (params: ApiCallParams) => Promise<ReportData> = getAllReports;

        if (props.partnerId) {
            // get the report for a specific partner
            params.id = props.partnerId;
            backendCall = getPartnerReports;
        } else if (props.brandId) {
            // get the report for a specific brand
            params.id = props.brandId;
            backendCall = getBrandReports;
        }

        // get the admin report for everything
        backendCall(params).then((output) => {
            // Sometimes, we won't get results from the DB
            if (!output || output.rows.length === 0) {
                setErrorMsg("There is no reporting data for the specified dates")
                setError(true);
            } else {
                // "report_" scope "_" [level-of-detail "_"] type "_" from "_" to "." extension
                let fileName = "report_";
                // level-of-detail is only included if it is is finer than the scope.
                // We want to avoid names containing e.g. "global_global" or "brand-ACME_brands".
                let levelToNotBeIncluded;
                if (props.partnerId) {
                    levelToNotBeIncluded = ReportLevelOfDetail.PARTNERS;
                    fileName += "partner-" + output.rows[0].partnerAlias + "_";
                } else if (props.brandId) {
                    levelToNotBeIncluded = ReportLevelOfDetail.BRANDS;
                    fileName += "brand-" + output.rows[0].brandAlias + "_";
                } else {
                    levelToNotBeIncluded = ReportLevelOfDetail.GLOBAL;
                    fileName += "global_";
                }
                const fileNameLevelOfDetail = params.levelOfDetail !== levelToNotBeIncluded ? params.levelOfDetail + "_" : "";
                fileName += fileNameLevelOfDetail + params.type + "_" + params.from + "_" + params.to;
                // We need to avoid converting the PAI/From text to a number.
                // https://github.com/react-csv/react-csv/issues/136
                output.rows.map((line) => {
                    if (line.callerNumber) {
                        line.callerNumber = '=""' + line.callerNumber + '""'
                    }
                });
                setHeaders(output.headers);
                setData(output.rows);
                setCsvFileName(fileName);
                csvLinkRef.current?.link.click();
            }
        }).catch((err) => {
            setErrorMsg(extractErrorMessage(err))
            setError(true);
        });
    }

    return (
        <>
            <Formik
                initialValues={{
                    from: "",
                    to: "",
                    type: "",
                    levelOfDetail: "",
                    callAnalytics: false
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values: FormikValues) => {
                    handleRequestReport(values);
                }}
            >
                {({ errors, values, setFieldValue }) => (
                    <Form>
                        <div className={classes.marginStyle}></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <DatePicker
                                        label="Start date"
                                        value={values.from}
                                        onChange={(v) => setFieldValue("from", v)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DatePicker
                                        className="datePicker"
                                        label="End date"
                                        value={values.to}
                                        onChange={(v) => setFieldValue("to", v)} />
                                </Grid>
                            </Grid>
                            {(errors.to || errors.from) && (
                                <FormHelperText error={true}>
                                    {errors.to || errors.from}
                                </FormHelperText>
                            )}
                        </LocalizationProvider>
                        <div className={classes.marginStyle}></div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography fontWeight="bold">Level of detail</Typography>
                                <RadioSelect
                                    name="levelOfDetail"
                                    options={reportLevelOptions}
                                />
                                {(errors.levelOfDetail) && (
                                    <FormHelperText error={true}>
                                        Please select the level of detail
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontWeight="bold">Type</Typography>
                                <RadioSelect
                                    name="type"
                                    options={[
                                        { label: "Aggregate", value: "aggregate" },
                                        { label: "Raw", value: "raw" },
                                    ]}
                                />
                                {(errors.type) && (
                                    <FormHelperText error={true}>
                                        Please choose a report type
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    type="checkbox"
                                    name="callAnalytics"
                                    as={FormControlLabel}
                                    control={<Checkbox />}
                                    label="Include call analytics"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.button}
                        >
                            Request Report
                        </Button>
                    </Form>
                )}
            </Formik>
            {/* CSV link is hidden as it's a pain to style. 
                Instead we trigger a click on it when we click the button above via its ref
                TODO this should probably go to the page rather than the component*/}
            <CSVLink
                hidden={true}
                headers={headers}
                data={data}
                filename={csvFileName}
                separator=";"
                enclosingCharacter='"'
                ref={csvLinkRef}
            />
            <AlertSnackback
                message={errorMsg}
                type="error"
                open={error}
                setOpen={setError}
            />
        </>
    );
}
