/**
 * @file Brand.tsx
 * @description Brand page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useEffect, useState } from "react";
import { Grid, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, DialogContentText, FormControlLabel, Checkbox } from "@mui/material";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Formik } from "formik";
import FieldFW from "../components/common/templates/forms/FieldFW";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import CampaignsTable from "../components/Campaigns/CampaignsTable";
import MSISDNsTable from "../components/MSISDNs/MSISDNsTable";
import { useUserContext } from "../context/UserContext";
import { validationSchema } from "../components/Brands/common/validationSchema";
import brandService, { BrandType } from "../services/brandService";
import campaignService, { AntiSpoofingType, ApprovalStatus, CampaignType } from "../services/campaignService";
import msisdnService, { MSISDNType } from "../services/msisdnService";
import EditLogo, { LogoType } from "../components/common/Logo/EditLogo";
import AlertDialog from "../components/common/templates/feedback/AlertDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import XlsxDropzone from "../components/Assets/XlsxDropzone";
import readXlsxFile from 'read-excel-file';
import { BulkUploadError, BulkUploadSummary } from "../components/MSISDNs/BulkUploadProgress/BulkUploadProgress";
import BulkUploadDialog from "../components/common/templates/feedback/BulkUploadDialog/BulkUploadDialog";
import partnerService from "../services/partnerService";
import AddMSISDN from "../components/MSISDNs/AddMSISDN";
import CampaignDesigner from "../components/Campaigns/CampaignDesigner";
import PaiDiscovery from "../components/MSISDNs/PaiDiscovery";
import { extractErrorMessage } from "../utils/utils";
import { isAntiSpoofingFeatureEnabled } from "../utils/featureToggle";
import { invalidateBrandQueries, invalidateCampaignQueries, invalidateMsisdnQueries } from "../utils/invalidateQueries";

/**
 * Props
 * @typedef {{brandId: string}} Props
 */
interface Props {
  brandId?: string;
}
/**
 * Renders Brand page
 * @param props component props @see Props
 * @returns {React.Component} Brand page
 */
/**
 * State
 * @typedef {{brandAlias: string}} State
 */
interface State {
  partnerAlias?: string;
}

export default function Brand(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { brandId: brandIdFromUrlParams } = useParams<{ brandId: string }>();
  const { user } = useUserContext();
  const { role } = user;
  const { state } = useLocation() as { state: State };
  const [msg, setMsg] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [openAddMsisdn, setOpenAddMsisdn] = useState(false);
  const [openPaiDiscovery, setOpenPaiDiscovery] = useState(false);
  const [openAddCampaign, setOpenAddCampaign] = useState(false);

  // Bulk Upload file upload
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [hasSelectedFile, setHasSelectedFile] = useState(false);
  const [target, setTarget] = useState<File>();
  const bulkUploadMaxRows = 500;

  // Bulk Upload progress
  const freshSummary = {newNumbers: 0, updatedNumbers: 0, unchangedNumbers: 0, newCampaigns: 0};
  const [bulkUploadSummary, setBulkUploadSummary] = useState<BulkUploadSummary>(freshSummary);
  const [bulkUploadErrors, setBulkUploadErrors] = useState<BulkUploadError[]>([]);
  const [openBulkUploadProgress, setOpenBulkUploadProgress] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Reset everything when the popups are closed
  useEffect(() => {
    setTarget(undefined);
    setHasSelectedFile(false);
    setBulkUploadSummary(freshSummary);
    setBulkUploadErrors([]);
  }, [openBulkUpload, openBulkUploadProgress]);
  
  const variant =
    role === "vodafone-admin" || role === "partner-manager"
      ? "outlined"
      : "standard";
  const areBrandDetailsReadOnly =
    role === "vodafone-admin" || role === "partner-manager" ? false : true;
  const canEditCampaignsNumbers = 
    role === "brand-manager" || role === "partner-manager" || role === "vodafone-admin";

  let brandId: string;
  if (!props.brandId) {
    brandId = brandIdFromUrlParams as string;
  } else {
    brandId = props.brandId;
  }

  const {
    data: brand,
    isLoading: loadingBrand,
    error: brandError,
  } = useQuery<BrandType>(["brand", brandId], () => brandService.getSingle(brandId));

  const [antiSpoofingChecked, setAntiSpoofingChecked] = useState(brand?.feature_anti_spoofing_enabled);
  const handleAntiSpoofingChange = (event: any) => {
    setAntiSpoofingChecked(event.target.checked);
  };

  useEffect(() => {
    setAntiSpoofingChecked(brand?.feature_anti_spoofing_enabled);
  }, [brand]);

  const {
    data: campaigns,
    isLoading: loadingCampaigns,
    error: campaignsError,
  } = useQuery<CampaignType[]>(["campaigns", brandId], () => campaignService.getByParam({ brandId: brandId }));

  const {
    data: msisdns,
    isLoading: loadingMsisdns,
    error: msisdnsError,
  } = useQuery<MSISDNType[]>(["msisdns", brandId], () => msisdnService.getAll(brandId));

  const queryClient = useQueryClient();

  const { mutateAsync: updateBrand } = useMutation(
    async (props: any) => {
      await brandService.update(props);
    },
    {
      onSuccess: () => {
        setMsg("Brand updated!");
        setSuccess(true);
        invalidateBrandQueries(queryClient);
      }
    }
  );

  const { mutateAsync: deleteBrand } = useMutation(brandService.delete, {
    onSuccess: () => {
      setMsg("Brand deleted!");
      setSuccess(true);
      invalidateBrandQueries(queryClient);
      navigate("../..", {relative: "path"});
    }
  });

  const manageUsers = (brandAlias: string, partnerAlias?: string) => {
    if (brandAlias && partnerAlias) {
      navigate(location.pathname + "/users", {
        state: { partnerAlias: partnerAlias, brandAlias: brandAlias },
      });
    } else {
      navigate(location.pathname + "/users", {
        state: { brandAlias: brandAlias },
      });
    }
  };

  const { mutateAsync: createMSISDN } = useMutation(msisdnService.create, {
    onSuccess: () => {
      invalidateMsisdnQueries(queryClient);
      invalidateCampaignQueries(queryClient);
    }
  });

  const { mutateAsync: updateMSISDN } = useMutation(msisdnService.update, {
    onSuccess: () => {
      invalidateMsisdnQueries(queryClient);
      invalidateCampaignQueries(queryClient);
    }
  });

  const { mutateAsync: createCampaign } = useMutation(campaignService.create, {
    onSuccess: () => {
      // do not show message for bulk upload
      if (openAddCampaign) {
        setOpenAddCampaign(false);
        setMsg("Campaign created!");
        setSuccess(true);
      }
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  /** 
     * Return true if a campaign with the same name already exists for this Brand. 
     * False, otherwise.
    */
  function isCampaignNameAlreadyUsed(campaignName: string): boolean {
    return campaigns?.some(campaign => campaign.name === campaignName) || false;
  }

  /** 
     * Convert a date into an ISO date string like 2023-06-23,
     * using the local time of the date.
    */
  function toIsoDate(date: Date): string {
    return date.getFullYear() + "-"
        + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
        + ("0" + date.getDate()).slice(-2);
  }

  /** 
     * Create a BulkUploadError and add it to errors state
    */
  function createBulkUploadError(
    errors: BulkUploadError[],
    pai: string, 
    from: string, 
    campaignDisplayName: string, 
    brandAlias: string, 
    errorMessage: string) {
      let error : BulkUploadError = {
        pai: pai,
        from: from,
        campaignDisplayName: campaignDisplayName,
        brandAlias: brandAlias,
        errorMsg: errorMessage
      }
      errors.push(error);
      setBulkUploadErrors(errors);
    }
  
  async function createBulkUploadCampaign(campaignDisplayName: string, msisdnId?: string) {
    const currentDate = toIsoDate(new Date());
    const newCampaign : CampaignType = {
      name: campaignDisplayName,
      display_name: campaignDisplayName,
      desc: "Bulk upload on " + currentDate,
      brand_id: brand!.id,
      msisdn_ids: msisdnId? [msisdnId] : [],
      approval_status: ApprovalStatus.APPROVED,
      anti_spoofing: AntiSpoofingType.DISABLED
    }
    return await createCampaign(newCampaign);
  }

  const handleDelete = () => {
    setOpenConfirm(false);
    deleteBrand(brandId).catch((err) => {
      setMsg(extractErrorMessage(err));
      setError(true);
    });
  };

  const isLoading: boolean = loadingBrand || loadingCampaigns || loadingMsisdns;
  const renderError = brandError || campaignsError || msisdnsError;

  let campaignsMap = new Map(campaigns?.map(campaign => [campaign.id as string, campaign]));

  if (isLoading) return <OnLoadWithNav />;
  if (renderError) return <OnErrorWithNav error={renderError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>{brand!.name}</Title>
          </Paper>
        </Grid>
        <Grid item lg={9} md={7} sm={7} xs={12}>
          <Paper className={classes.paper}>
            <Title>Brand Details</Title>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={{
                id: brand!.id,
                name: brand!.name,
                desc: brand!.desc,
                alias: brand!.alias,
                signature: brand!.signature,
                account_email: brand!.account_email,
                partner_id: brand!.partner_id,
                pai_contingent: brand!.pai_contingent
              }}
              validationSchema={validationSchema}
              onSubmit={async (brand, { setSubmitting }) => {
                setSubmitting(true);
                try {
                  await updateBrand({
                    ...brand, 
                    feature_anti_spoofing_enabled: antiSpoofingChecked as boolean
                  });  
                } catch (err: any) {
                  setError(true);
                  setMsg(extractErrorMessage(err));
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="name"
                        name="name"
                        placeholder="Name"
                        variant={variant}
                        readonly={areBrandDetailsReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="desc"
                        name="desc"
                        placeholder="Description"
                        variant={variant}
                        readonly={areBrandDetailsReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="alias"
                        name="alias"
                        placeholder="Alias"
                        variant={variant}
                        readonly={true}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="end">
                      <Grid item xs={3}>
                        <FieldFW
                          type="number"
                          required
                          id="pai_contingent"
                          name="pai_contingent"
                          placeholder="PAI Contingent"
                          variant={variant}
                          readonly={areBrandDetailsReadOnly}
                        />
                      </Grid>
                      <Grid item xs={5} marginLeft="1em">
                        {brand!.pai_contingent - new Set(msisdns!.map((number) => number.pai)).size} out of {brand!.pai_contingent} PAIs are available
                      </Grid>
                    </Grid>
                    {isAntiSpoofingFeatureEnabled() &&
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Enable Anti-Spoofing feature"
                          checked={!!antiSpoofingChecked}
                          onChange={handleAntiSpoofingChange}
                          disabled={areBrandDetailsReadOnly}
                          control={<Checkbox />}
                        />
                      </Grid>
                    }
                    {!areBrandDetailsReadOnly && (
                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            Update Brand Details
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => setOpenConfirm(true)}
                          >
                            Delete Brand
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid item lg={3} md={5} sm={5} xs={12}>
          <Paper className={classes.paper}>
            <Title>Logo</Title>
            <EditLogo
              id={brand?.id as string}
              type={LogoType.BRAND}
              setError={(msg) => {
                setMsg(msg);
                setError(true);
              }}
              logoSrc={brand?.logo as string}
              rolesAllowedToChangeLogo={["vodafone-admin", "partner-manager"]}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Campaigns</Title>

            {canEditCampaignsNumbers && (
              <Grid item xs={12}>
                <div className={classes.buttons}>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddCampaign(true)}
                  >
                    Add Campaign
                  </Button>

                </div>
              </Grid>
            )}

            <CampaignsTable
              data={campaigns!}
              brand={brand!}
              msisdns={msisdns!}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />

          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Numbers</Title>
            
            {canEditCampaignsNumbers && (
              <Grid item xs={12}>
                <div className={classes.buttons}>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddMsisdn(true)}
                  >
                    Add Number
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.tableButtons}
                    onClick={() => setOpenBulkUpload(true)}
                  >
                    Bulk Upload
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.tableButtons}
                    onClick={() => setOpenPaiDiscovery(true)}
                  >
                    PAI Discovery
                  </Button>

                </div>
              </Grid>
            )}

            <MSISDNsTable
              data={msisdns!}
              campaignsMap={campaignsMap}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />
          </Paper>
        </Grid>

        {canEditCampaignsNumbers && (
          <AddMSISDN
            open={openAddMsisdn}
            handleClickClose={() => setOpenAddMsisdn(false)}
            brandId={brandId}
          />
        )}

        {canEditCampaignsNumbers && (
          <PaiDiscovery
            brandId={brandId}
            open={openPaiDiscovery}
            handleClickClose={() => setOpenPaiDiscovery(false)}
            setErrorMsg={(msg) => {
              setMsg(msg);
              setError(true);
            }}
          />
        )}

        {canEditCampaignsNumbers && (
          <CampaignDesigner
            brandId={brandId}
            saveCampaign={createCampaign}
            open={openAddCampaign}
            setOpen={setOpenAddCampaign}
            setErrorMsg={(msg) => {
              setMsg(msg);
              setError(true);
            }}
          />
        )}

        <Dialog
          open={openBulkUpload}
          onClose={() => setOpenBulkUpload(false)}
          aria-labelledby="form-dialog-title"
          style={{ width: "100%" }}
        >

          <DialogTitle id="form-dialog-title">Bulk Upload</DialogTitle>
          <DialogContent>

            <DialogContentText>
              <Typography paragraph>
                Bulk Upload adds a list of Numbers to this Brand.
              </Typography>
              <Typography paragraph>
                Download <Link to="/bulk-upload-template.xlsx" target="_blank" download>this Excel template</Link>, 
                follow the instructions on the first worksheet, enter the Numbers on the second worksheet, 
                and finally upload the updated Excel document in this dialog.
              </Typography>
            </DialogContentText>

            <Formik
              enableReinitialize={true}
              initialValues={{
                file: target as File,
              }}
              onSubmit={(data, { setSubmitting }) => {
                setOpenBulkUploadProgress(true);
                setSubmitting(true);
                setUploading(true);
                readXlsxFile(target as File, { sheet: 2 }).then(async (content: any) => {
                  // `content` is an array of rows
                  // each row being an array of cells.
                  /**
                  [
                    [ 
                      // header row
                      "Number (PAI Header)"
                      "Number (FROM Header)"
                      "Campaign Name (Display Name)"
                      "Brand Alias"
                    ],
                    [
                      "+123456789", - PAI
                      "+123456789", - From (optional)
                      "campaign",   - Campaign display name (optional)
                      "BRAND"       - Brand alias
                    ],
                    [
                      "+222222222",
                      null,
                      null,
                      "BRAND"
                    ]
                  ]
                  */
                  
                  // keep track of resources created during this Bulk Upload
                  let campaignsMap: Map<String, CampaignType> = new Map();
                  let notUniqueDisplayNames: Set<String> = new Set();

                  campaigns!.forEach(campaign => {
                    if (campaignsMap.get(campaign.display_name)) {
                      notUniqueDisplayNames.add(campaign.display_name);
                    } else {
                      campaignsMap.set(campaign.display_name, campaign);
                    }
                  })

                  let summary: BulkUploadSummary = freshSummary;
                  let errors: BulkUploadError[] = [];

                  // We need to skip the first row (header).
                  const rowsWithoutHeader = content.slice(1);

                  if (rowsWithoutHeader.length > bulkUploadMaxRows) {
                    rejectBulkUploadWithMessage("Bulk Upload was rejected because the file has more than " + bulkUploadMaxRows + " numbers.");
                    return;
                  }

                  const uniquePais: Set<string> = new Set(msisdns!.map((number) => number.pai));
                  // Count the number of new PAIs up front.
                  // The whole bulk upload fails if it would exhaust the PAI contingent.
                  for (const row of rowsWithoutHeader) {
                    const pai: string = row[0] as string;
                    uniquePais.add(pai);
                  }
                  if (uniquePais.size > brand!.pai_contingent) {
                    rejectBulkUploadWithMessage("Bulk Upload was rejected because it would exhaust the PAI Contingent");
                    return;
                  }

                  for (const row of rowsWithoutHeader) {

                    const pai: string = row[0] as string;
                    const from: string = row[1] as string;
                    const campaignDisplayName: string = row[2] as string;
                    const brandAlias: string = row[3] as string;

                    // check if PAI is not empty
                    if (!pai) {
                      createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, "PAI is required");
                      continue;
                    }

                    // check if Brand alias matches with selected Brand
                    if (brandAlias !== brand?.alias) {
                      const errorMsg = "Brand alias in Excel row does not match the alias of selected Brand";
                      createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMsg);
                      continue;
                    }

                    // check if Number already exists for selected Brand
                    let existentNumber: MSISDNType | undefined = undefined;

                    for (const existentMsisdn of msisdns!) {
                      if (pai === existentMsisdn.pai && from === existentMsisdn.from) {
                        existentNumber = existentMsisdn;
                      }
                    }

                    if (notUniqueDisplayNames.has(campaignDisplayName)) {
                      const errorMsg = "Multiple campaigns with this display name were found";
                      createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMsg);
                      continue;
                    }

                    let existentCampaign: CampaignType | undefined = campaignsMap.get(campaignDisplayName);

                    if (!existentNumber) {

                      // Number does not exist

                      // create campaign if necessary
                      if (campaignDisplayName && !existentCampaign) {
                        if (isCampaignNameAlreadyUsed(campaignDisplayName)) {
                          const errorMsg = "A campaign with this name (but different display name) already exists for this Brand";
                          createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMsg);
                          continue;
                        }
                        let result; 
                        try {
                          result = await createBulkUploadCampaign(campaignDisplayName);
                        } catch (err: any) {
                          const errorMessage = "Error creating campaign. " + extractErrorMessage(err);
                          createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMessage);
                          continue;
                        }
                        campaignsMap.set(campaignDisplayName, result);
                        existentCampaign = result;
                        summary.newCampaigns++;
                      }

                      let campaignId: string | undefined = existentCampaign?.id;

                      // Create the number with the associated campaign
                      let msisdn : MSISDNType = {
                        pai: pai,
                        from: from? from : undefined,
                        campaign_id: campaignId,
                        brand_id: brand.id,
                      }

                      try {
                        await createMSISDN(msisdn);
                      } catch (err: any) {
                        createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, extractErrorMessage(err));
                        continue;
                      }
                      summary.newNumbers++;

                    } else {

                      // Number already exists

                      if (campaignDisplayName && !existentCampaign) {

                        // Campaign needs to be created
                        if (isCampaignNameAlreadyUsed(campaignDisplayName)) {
                          const errorMsg = "A campaign with this name (but different display name) already exists for this Brand";
                          createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMsg);
                          continue;
                        }
                        let result;
                        try {
                          result = await createBulkUploadCampaign(campaignDisplayName, existentNumber.id as string);
                        } catch (err: any) {
                          const errorMessage = "Error creating campaign. " + extractErrorMessage(err);
                          createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, errorMessage);
                          continue;
                        }
                        summary.updatedNumbers++;
                        campaignsMap.set(campaignDisplayName, result);
                        existentCampaign = result;
                        summary.newCampaigns++;

                      } else if (existentNumber.campaign_id != existentCampaign?.id) {
                        // note != instead of !==
                        // we do not want an update if both are empty/null/undefined

                        // Campaigns (of input and existent number) are different, 
                        // number needs to be updated

                        let campaignId: string | undefined = existentCampaign?.id;

                        let msisdn : MSISDNType = {
                          ...existentNumber,
                          // We need "from" to be undefined instead of null/empty
                          from: existentNumber.from? existentNumber.from : undefined,
                          campaign_id: campaignId,
                        };
                        try {
                          await updateMSISDN(msisdn);
                        } catch (err: any) {
                          createBulkUploadError(errors, pai, from, campaignDisplayName, brandAlias, extractErrorMessage(err));
                          continue;
                        }
                        summary.updatedNumbers++;
                      } else {
                        // number is already in the correct state, do nothing
                        summary.unchangedNumbers++;
                      }

                    }
                    setBulkUploadSummary(summary)
                  }
                  setUploading(false);

                  function rejectBulkUploadWithMessage(message: string) {
                    setMsg(message);
                    setError(true);
                    setUploading(false);
                    setOpenBulkUploadProgress(false);
                    setSubmitting(false);
                    setOpenBulkUpload(false);
                  }
                })

                setSubmitting(false);
                setOpenBulkUpload(false);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <XlsxDropzone
                      target={target}
                      setTarget={setTarget}
                      setHasSelectedFile={setHasSelectedFile}
                    />
                      {}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button type="submit" disabled={isSubmitting || !hasSelectedFile} color="primary">
                      Upload
                    </Button>
                    <Button onClick={() => setOpenBulkUpload(false)} color="secondary">
                      Cancel
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        {role === "partner-manager" || role === "vodafone-admin" ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Users</Title>
              <Button
                onClick={
                  state && state.partnerAlias
                    ? () =>
                      manageUsers(
                        brand!.alias,
                        state.partnerAlias as string
                      )
                    : async () => 
                      manageUsers(
                        brand!.alias,
                        await partnerService.getAlias(brand!.partner_id)
                      )
                }
              >
                Manage User Permissions
              </Button>
            </Paper>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <BulkUploadDialog
        open={openBulkUploadProgress}
        setOpen={setOpenBulkUploadProgress}
        bulkUploadSummary={bulkUploadSummary} 
        bulkUploadErrors={bulkUploadErrors}
        uploading={uploading}
      />
      <AlertDialog
        resourceName="this Brand"
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleYes={handleDelete}
      />
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
