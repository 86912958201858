/**
 * @file PartnerManagerRoutes.tsx
 * @description Defines routes for Partner Manager role.
 * @requries react-router-dom
 * @author Harry Rhodes
 * @exports BrowserRouter
 */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../../../../../pages/Dashboard";
import Partner from "../../../../../pages/Partner";
import Brand from "../../../../../pages/Brand";
import Assets from "../../../../../pages/Assets";
import Campaign from "../../../../../pages/Campaign";
import Users from "../../../../../pages/Users";
import User from "../../../../../pages/User";
import NotFound from "../../../../../pages/error/NotFound";
import Reports from "../../../../../pages/Reports";
import { isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";

/**
 * Props
 * @typedef {{partnerId: string}} Props
 */
interface Props {
  partnerId: string;
}
/**
 * Renders PartnerManagerRoutes
 * @param props component props @see Props
 * @returns {React.Component} PartnerManagerRoutes
 */
export default function PartnerManagerRoutes(props: Props) {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/* Partner Info Routes */}
        <Route path={"/partnerInfo"} element={<Partner partnerId={props.partnerId} />} />
        <Route path={"/partnerInfo/brands/:brandId"} element={<Brand />} />
        <Route path={"/partnerInfo/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/partnerInfo/brands/:brandId/users"} element={<Users />} />
        <Route path={"/partnerInfo/brands/:brandId/users/:username"} element={<User />} />
        {/* Nominal Routes */}
        <Route path={"/brands/:brandId"} element={<Brand />} />
        <Route path={"/brands/:brandId/campaigns/:campaignId"} element={<Campaign />} />
        <Route path={"/brands/:brandId/users"} element={<Users />} />
        <Route path={"/brands/:brandId/users/:username"} element={<User />} />
        <Route path={"/users"} element={<Users />} />
        <Route path={"/users/:username"} element={<User />} />
        <Route path={"/reports"} element={<Reports partnerId={props.partnerId} />} />
        <Route path="*" element={<NotFound authenticated={true} />} />
        {isAssetsFeatureEnabled() &&
          <>
            <Route path={"/partnerInfo/brands/:brandId/assets"} element={<Assets />} />
            <Route path={"/brands/:brandId/assets"} element={<Assets />} />
          </>
        }
      </Routes>
    </BrowserRouter>
  );
}
