/**
 * @file opcoService.ts
 * @description Interface/client for interacting with local /opco API.
 * @requries ./axiosClient
 * @author Harry Rhodes
 */

import { client } from "./axiosClient";

export type OPCOCreateType = {
  name: string;
  alias: string;
  market: string;
  account_email: string;
  image?: File;
};
/**
 * @typedef OPCOType
 * @property {string} id - DB generated id
 * @property {string} logo - S3 key for OPCO logo
 * @property {string} name - Name of OPCO
 * @property {string} alias - OPCO alias
 * @property {string} market - Name of operating market
 * @property {string} account_email - Primary account for OPCO
 * @property {File} image - Image of logo for OPCO
 */
export type OPCOType = {
  id: string;
  logo: string;
  name: string;
  alias: string;
  market: string;
  account_email: string;
};

const service = {
  getAll: async (
    aggregatorId?: string,
    partnerId?: string
  ): Promise<OPCOType[]> => {
    let res;
    if (aggregatorId) {
      res = await client.get("/opcos", {
        params: { aggregator_id: aggregatorId },
      });
    } else if (partnerId) {
      res = await client.get("/opcos", {
        params: { partner_id: partnerId },
      });
    } else {
      res = await client.get("/opcos");
    }
    return res.data;
  },
  getSingle: async (id: string): Promise<OPCOType> => {
    let res = await client.get("/opcos/" + id);
    return res.data[0] || [];
  },
  getId: async (alias: string) => {
    let res = await client.get("/opcos", { params: { alias: alias } });
    return res.data[0].id || [];
  },
  getName: async (alias: string) => {
    let res = await client.get("/opcos", { params: { alias: alias } });
    return res.data[0]?.name || "";
  },
  create: async (body: FormData) => {
    let res = await client.post("/opcos", body);
    return res.data || [];
  },
  update: async (body: OPCOType) => {
    let res = await client.put("/opcos/" + body.id, body);
    return res.data || [];
  },
  updateLogo: async (id: string, body: FormData) => {
    let res = await client.patch("/opcos/" + id + "/logo", body);
    return res.data || [];
  },
  delete: async (id: string) => {
    let res = await client.delete("/opcos/" + id);
    return res.data || [];
  },
};

export default service;
