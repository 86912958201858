/**
 * @file validationSchema.ts Form input validation for the reports form
 * @author Christian Vasold
 * @exports validationSchema
 */
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    from: Yup
        .date()
        .required("Please select start date"),
    to: Yup
        .date()
        .required("Please select end date")
        .test("dates-are-ok", "Please make sure the end date is not before the start date", function (value) {
            return value === undefined || this.parent.from <= value;
        })
        .test("end-date-is-ok", "Please make sure the end date is not later than yesterday", function (value) {
            if (!value) {
                return true;
            }
            const yesterday: Date = new Date();
            yesterday.setUTCDate(yesterday.getUTCDate() - 1);
            const valueUTC = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
            return valueUTC <= yesterday;
        })
        .test("yesterday-report-is-ready", "The data for the previous day will only be available at 03:00 GMT+1.", function (value) {
            if (!value) {
                return true;
            }
            const now: Date = new Date();
            const yesterdayUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
            const valueUTC = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
            if (valueUTC.getTime() === yesterdayUTC.getTime()) {
                // The report for the previous day will only be available at 02:00 UTC.
                if (now.getUTCHours() < 2) {
                    return false;
                }
            }
            return true;
        }),
    type: Yup
        .string()
        .oneOf(["raw", "aggregate"])
        .required("Please select type"),
    levelOfDetail: Yup
        .string()
        .oneOf(["global", "partners", "brands", "campaigns", "numbers"])
        .required("Please select the level of detail"),
    callAnalytics: Yup
        .boolean().required()
});