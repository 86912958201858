/**
 * @file BrandManagerList.tsx
 * @description Defines Navigation list items for Brand Manager role.
 * @author Harry Rhodes
 * @exports React.Fragment
 */
import { useState } from "react";
import { List, Collapse, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ImageIcon from "@mui/icons-material/Image";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import AssessmentIcon from "@mui/icons-material/Assessment";
import useStyles from "../common/styles";
import { getDashboardMenuTitle, isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";

/**
 * Props
 * @typedef {{partnerId: string, brandId: string}} Props
 */
interface Props {
  partnerId: string;
  brandId: string;
}
/**
 * Renders BrandManagerList
 * @param props component props @see Props
 * @returns {React.Component} BrandManagerList
 */
export default function BrandManagerList(props: Props) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <BusinessCenterIcon />
        </ListItemIcon>
        <ListItemText primary="Your Brand" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/brandInfo"}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Brand Info" />
          </ListItemButton>

          {isAssetsFeatureEnabled() &&
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/assets"}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary="Assets" />
            </ListItemButton>
          }
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/users"}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/reports"}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
