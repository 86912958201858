import * as Yup from "yup";

/* eslint-disable no-template-curly-in-string */

export const yupForPaiContingent: Yup.NumberSchema<any> = 
  Yup.number()
    .integer("PAI Contingent must be an integer")
    .positive("PAI Contingent must be a positive number")
    .max(1000000, "The maximum value allowed is 1.000.000")
    // Anything that is not a valid number becomes `undefined`.
    // So required(message) is enough to validate the input and show an appropriate error.
    .required("Valid number required");

export const yupForAlias: Yup.StringSchema<any> = 
  Yup.string()
    .min(3, "Alias cannot be shorter than ${min} characters")
    .max(15, "Alias cannot be longer than ${max} characters")
    .required("Required")
    .matches(/^[._0-9a-zA-Zà-öÀ-Öø-þØ-Þ]*$/, "Alias contains invalid characters");

// Yup string that rejects leading and trailing spaces.
export function yupTrimmedString(fieldPrettyName: string): Yup.StringSchema<any> {
  return Yup.string()
    .strict()
    .trim(fieldPrettyName + " cannot contain leading or trailing spaces");
}

/**
 * Creates an Yup schema to validate a display name field.
 * @param fieldPrettyName field name that will be shown to the user in case of an error.
 */
export function createYupDisplayName(fieldPrettyName: string) {
  return yupTrimmedString(fieldPrettyName)
    .min(2, fieldPrettyName + " cannot be shorter than ${min} characters")
    .max(30, fieldPrettyName + " cannot be longer than ${max} characters")
    // Accept all characters from GSM 7-bit default basic character set
    // (3GPP TS 23.038 / GSM 03.38, see also https://en.wikipedia.org/wiki/GSM_03.38)
    // with the following exceptions:
    //  * non-printable characters (LF, CR, ESC)
    //  * double quote (")
    //
    // Tests by Vodafone/Nokia have shown that double quote causes problems.
    // Background: as per RFC 3261 the display-name is defined as
    //   display-name = *(token LWS) / quoted-string
    // and in quoted strings, quotation marks (") need to be escaped.
    // Even in escaped format we saw some impact.
    //
    // Initially, there was some dispute on whether
    // Ç (LATIN CAPITAL LETTER C WITH CEDILLA) or
    // ç (LATIN SMALL LETTER C WITH CEDILLA) should be allowed.
    // See also the discussion in http://www.unicode.org/Public/MAPPINGS/ETSI/GSM0338.TXT
    // Tests by Vodafone/Nokia have shown that only Ç gets mapped to 7-bit 0x09,
    // so Ç is supported, but ç isn't.
    //
    // The regular expression accepts the following ranges:
    //  * space ( ), underscore (_), exclamation mark (!)
    //  * char range from #-Z. Includes #$%&'()*+,-./0-9:;<=>?@A-Z
    //  * char range from a-z.
    //  * special symbols and letters that are explicitly defined.
    .matches(/^[ _!#-Za-z£¥èéùìòØøÅåΔΦΓΛΩΠΨΣΘΞÆæßÉ¤¡ÄÖÑÜ§¿äöñüàÇ]+$/,
      "Only characters from the GSM 7-bit default basic character set (3GPP TS 23.038 / GSM 03.38) are allowed, excluding quotation mark (\")")
    // reject adjacent spaces
    .test(
      "Check for adjacent spaces",
      fieldPrettyName + " cannot contain adjacent spaces",
      (value: any, ctx: Yup.TestContext) => !value?.includes("  ")
    )
    .required("Required");
}
