/**
 * @file CampaignsTable.tsx CampaignsTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useNavigate, useLocation } from "react-router-dom";
import campaignService, { AntiSpoofingType, CampaignType } from "../../../services/campaignService";
import { BrandType } from "../../../services/brandService";
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useState } from "react";
import useStyles, { sxProps } from "./styles";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import { MSISDNType } from "../../../services/msisdnService";
import { useMutation, useQueryClient } from "react-query";
import { invalidateCampaignQueries, invalidateMsisdnQueries } from "../../../utils/invalidateQueries";
import { Typography } from "@mui/material";
import { useUserInfo } from "../../../hooks/useUserInfo";

/**
 * Props
 * @typedef {{brandId: unknown}} Props
 */
interface Props {
  data: CampaignType[];
  brand: BrandType;
  msisdns: MSISDNType[];
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
}

/**
 * Renders CampaignsTable component
 * @param props component props @see Props
 * @returns {React.Component} CampaignsTable component
 */
export default function CampaignsTable(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useUserInfo();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteCampaign } = useMutation((ids: string[]) => Promise.all(ids.map(campaignService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage("Deleted " + results.length + " Campaign(s)!");
    },
    onSettled: () => {
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  /**
   * Navigates to relevent brand based on role/current location
   */
  const handleClick = (id: string) => {
    if (location.pathname.includes("campaigns")) {
      navigate(location.pathname + "/" + id);
    } else if (location.pathname.includes("msisdns")) {
      navigate(location.pathname + "/../campaigns/" + id);
    } else {
      navigate(location.pathname + "/campaigns/" + id);
    }
  };

  if (props.data.length === 0) return <Typography marginTop="1em">No Campaigns</Typography>;

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 350 },
    { field: 'displayName', headerName: 'Display Name', width: 250 },
    { field: 'antiSpoofing', headerName: 'Anti-Spoofing', width: 250 },
    { field: 'assignedNumbers', headerName: 'Assigned Numbers', width: 145, align: 'right' }
  ];

  const rows = props.data.map((campaign) => ({
    id: campaign.id,
    name: campaign.name,
    displayName: campaign.display_name,
    assignedNumbers: props.msisdns.filter((msisdn) => msisdn.campaign_id === campaign.id).length,
    antiSpoofing: AntiSpoofingType[campaign.anti_spoofing as keyof typeof AntiSpoofingType],
  }));

  const canDelete: boolean = role === "vodafone-admin" || role === "partner-manager" || role === "brand-manager";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            // Hide anti-spoofing column when feature is disabled
            antiSpoofing: props.brand.feature_anti_spoofing_enabled == true,
          },
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onRowClick={(row) => handleClick(row?.id as string)}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          resourceName: "the Campaign(s)",
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? deleteCampaign : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
