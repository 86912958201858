/**
 * @file EditLogo.tsx
 * @description EditLogo component
 * @author Jay Patel, Harry Rhodes
 * @exports React.Component
 */
import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import { Formik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import aggregatorService from "../../../../services/aggregatorService";
import brandService from "../../../../services/brandService";
import opcoService from "../../../../services/opcoService";
import partnerService from "../../../../services/partnerService";
import LogoCropper from "../../LogoCropper";
import { useUserContext } from "../../../../context/UserContext";
import useStyles from "./styles";
import defaultLogo from "../../../../assets/images/default-logo.png";
import { invalidateAggregatorQueries, invalidateBrandQueries, invalidateOpcoQueries, invalidatePartnerQueries } from "../../../../utils/invalidateQueries";
import { extractErrorMessage } from "../../../../utils/utils";

/**
 * Props
 * @typedef {id: number, type: LogoType, logoSrc: string, rolesAllowedToChangeLogo: string[]} Props
 */

export enum LogoType {
  AGGREGATOR,
  BRAND,
  OPCO,
  PARTNER,
}
interface Props {
  id: string;
  type: LogoType;
  setError: (s: string) => void;
  logoSrc?: string;
  rolesAllowedToChangeLogo?: string[];
}

/**
 * Renders EditBrand component
 * @param props component props @see Props
 * @returns {React.Component} EditBrand component
 */
export default function EditLogo(props: Props) {
  const classes = useStyles();
  const { id, type, setError, logoSrc } = props;
  const { user } = useUserContext();
  const { role } = user;
  const [open, setOpen] = useState(false);
  const [hasSelectedImage, setHasSelectedImage] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const [signedURL, setSignedURL] = useState("");
  const [target, setTarget] = useState<File>();

  useEffect(() => {
    async function getImgURL() {
      try {
        if (logoSrc) {
          const res = await Storage.get(logoSrc);
          setSignedURL(res);
        } else {
          setSignedURL(defaultLogo);
        }
      } catch (e) {
        return "";
      }
    }
    getImgURL();
  });

  useEffect(() => {
    setHasSelectedImage(false);
  }, [open]);

  const queryClient = useQueryClient();

  const { mutateAsync: updateAggregatorLogo, isLoading: loadingAggregator } =
    useMutation(
      async (props: any) => {
        await aggregatorService.updateLogo(props.id, props.formData);
      },
      {
        onSuccess: () => {
          invalidateAggregatorQueries(queryClient);
          // reload the page to refresh the logo. See https://rncorp.atlassian.net/browse/ERBT-6409
          window.location.reload();
        },
      }
    );
  const { mutateAsync: updateBrandLogo, isLoading: loadingBrand } = useMutation(
    async (props: any) => {
      await brandService.updateLogo(props.id, props.formData);
    },
    {
      onSuccess: () => {
        invalidateBrandQueries(queryClient);
        // reload the page to refresh the logo. See https://rncorp.atlassian.net/browse/ERBT-6409
        window.location.reload();
      },
    }
  );
  const { mutateAsync: updateOPCOLogo, isLoading: loadingOPCO } = useMutation(
    async (props: any) => {
      await opcoService.updateLogo(props.id, props.formData);
    },
    {
      onSuccess: () => {
        invalidateOpcoQueries(queryClient);
        // reload the page to refresh the logo. See https://rncorp.atlassian.net/browse/ERBT-6409
        window.location.reload();
      },
    }
  );
  const { mutateAsync: updatePartnerLogo, isLoading: loadingPartner } =
    useMutation(
      async (props: any) => {
        await partnerService.updateLogo(props.id, props.formData);
      },
      {
        onSuccess: () => {
          invalidatePartnerQueries(queryClient);
          // reload the page to refresh the logo. See https://rncorp.atlassian.net/browse/ERBT-6409
          window.location.reload();
        },
      }
    );

  const isLoading =
    loadingAggregator || loadingBrand || loadingOPCO || loadingPartner;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.mediaContainer}>
          <CardMedia
            component="img"
            src={signedURL}
            className={classes.media}
          />
        </Grid>
        {(props.rolesAllowedToChangeLogo ? 
          props.rolesAllowedToChangeLogo.includes(role) : 
          role === "vodafone-admin") && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              handleClickOpen();
            }}
          >
            Change Logo
          </Button>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
        style={{ width: "100%" }}
      >
        <DialogTitle id="form-dialog-title">Change Logo</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              image: target as File,
            }}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              let formData = new FormData();
              formData.append("file", target as File);
              let x = {
                id: id,
                formData: formData,
              };

              try {
                switch (type) {
                  case LogoType.AGGREGATOR:
                    await updateAggregatorLogo(x);
                    break;
                  case LogoType.BRAND:
                    await updateBrandLogo(x);
                    break;
                  case LogoType.OPCO:
                    await updateOPCOLogo(x);
                    break;
                  case LogoType.PARTNER:
                    await updatePartnerLogo(x);
                    break;
                }
              } catch (err: any) {
                setError(extractErrorMessage(err));
              }
              
              setSubmitting(false);
              setOpen(false);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LogoCropper setTarget={setTarget} setHasSelectedImage={setHasSelectedImage} />
                    {/* TODO: Add file upload progress
                     <div className={classes.progress}>
                      <LinearProgress
                        variant="determinate"
                        value={loadProgress}
                      />
                    </div> */}
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button type="submit" disabled={isSubmitting || !hasSelectedImage} color="primary">
                    Update
                  </Button>
                  <Button onClick={handleClickClose} color="secondary">
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={isLoading} aria-labelledby="form-dialog-title">
        <DialogTitle>Updating Logo ...</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.progress}>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
