/**
 * @file Partner.tsx
 * @description Partner page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Grid, Paper, Button } from "@mui/material";
import partnerService, { PartnerType } from "../services/partnerService";
import brandService, { BrandType } from "../services/brandService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Formik } from "formik";
import FieldFW from "../components/common/templates/forms/FieldFW";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import useStyles from "../components/common/templates/style";
import BrandsTable from "../components/Brands/BrandsTable";
import { validationSchema } from "../components/Partners/common/validationSchema";
import OPCOAvailableList from "../components/OPCOs/AvailableOPCOsChipList";
import OPCOChipList from "../components/OPCOs/OPCOsChipList";
import opcoService, { OPCOType } from "../services/opcoService";
import { useUserContext } from "../context/UserContext";
import EditLogo, { LogoType } from "../components/common/Logo/EditLogo";
import AlertDialog from "../components/common/templates/feedback/AlertDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { extractErrorMessage } from "../utils/utils";
import { invalidateBrandQueries, invalidatePartnerQueries } from "../utils/invalidateQueries";
import AddBrand from "../components/Brands/AddBrand";
/**
 * Props
 * @typedef {{partnerId: string}} Props
 */
interface Props {
  partnerId?: string;
}

/**
 * Renders Partner page
 * @param props component props @see Props
 * @returns {React.Component} Partner page
 */
export default function Partner(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { partnerId: partnerIdParam } = useParams<{ partnerId: string }>();
  const { user } = useUserContext();
  const { role } = user;
  const [currentOPCOs, setCurrentOPCOs] = useState<OPCOType[]>([]);
  const [availableOPCOs, setAvailableOPCOs] = useState<OPCOType[]>([]);
  const [signedURL, setSignedURL] = useState("");
  const [msg, setMsg] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [openAddBrand, setOpenAddBrand] = useState(false);

  const variant = role === "vodafone-admin" ? "outlined" : "standard";
  const readonly = role === "vodafone-admin" ? false : true;

  let partnerId: string;
  if (!props?.partnerId) {
    partnerId = partnerIdParam as string;
  } else {
    partnerId = props.partnerId as string;
  }

  const {
    data: partner,
    isLoading: loadingPartner,
    error: partnerError,
  } = useQuery<PartnerType>(["partner", partnerId], () =>
    partnerService.getSingle(partnerId)
  );

  const {
    data: brands,
    isLoading: loadingBrands,
    error: brandsError,
  } = useQuery<BrandType[]>(["brands", partnerId], () =>
    brandService.getAll(partnerId)
  );

  const {
    data: opcos,
    isLoading: loadingOPCOs,
    error: opcosError,
  } = useQuery<OPCOType[]>(["opcos"], () => opcoService.getAll(), {
    enabled: role === "vodafone-admin",
  });

  const {
    data: assignedOPCOs,
    isLoading: loadingAssignedOPCOs,
    error: assignedOPCOsError,
  } = useQuery<OPCOType[]>(
    ["opcos-for-partner", partnerId],
    () => opcoService.getAll(undefined, partnerId),
    { enabled: role === "vodafone-admin" }
  );

  const queryClient = useQueryClient();

  const { mutateAsync: updatePartner } = useMutation(partnerService.update, {
    onSuccess: () => {
      setMsg("Partner updated!");
      setSuccess(true);
      invalidatePartnerQueries(queryClient);
    }
  });

  const { mutateAsync: deletePartner } = useMutation(partnerService.delete, {
    onSuccess: () => {
      setMsg("Partner deleted!");
      setSuccess(true);
      invalidatePartnerQueries(queryClient);
      navigate("..", {relative: "path"});
    }
  });

  const { mutateAsync: createBrand } = useMutation(brandService.create, {
    onSuccess: () => {
      setOpenAddBrand(false);
      setMsg("Brand created!");
      setSuccess(true);
      invalidateBrandQueries(queryClient);
    }
  });

  const manageUsers = (alias: string) => {
    navigate(location.pathname + "/users", {
      state: { partnerAlias: alias },
    });
  };

  const handleDelete = () => {
    setOpenConfirm(false);
    deletePartner(partnerId).catch((err) => {
      setMsg(extractErrorMessage(err));
      setError(true);
    });
  };

  const isLoading: boolean = 
    loadingPartner || loadingBrands || loadingOPCOs || loadingAssignedOPCOs;
  const renderError =
    partnerError || brandsError || opcosError || assignedOPCOsError;

  useEffect(() => {
    if (opcos && assignedOPCOs) {
      setCurrentOPCOs(assignedOPCOs as OPCOType[]);
      if (opcos && assignedOPCOs) {
        setAvailableOPCOs(
          opcos?.filter(
            ({ id: id1 }) => !assignedOPCOs?.some(({ id: id2 }) => id2 === id1)
          ) as OPCOType[]
        );
      }
    }
  }, [isLoading]);

  useEffect(() => {
    async function getImgURL() {
      try {
        if (partner?.logo) {
          const res = await Storage.get(partner?.logo as string);
          setSignedURL(res);
        } else {
          const res = await Storage.get("default/logo/default-logo.png");
          setSignedURL(res);
        }
      } catch (e) {
        return <OnErrorWithNav error={e} />;
      }
    }
    getImgURL();
  });

  if (isLoading) return <OnLoadWithNav />;
  if (renderError) return <OnErrorWithNav error={renderError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>{partner?.name as string}</Title>
          </Paper>
        </Grid>

        <Grid item lg={9} md={7} sm={7} xs={12}>
          <Paper className={classes.paper}>
            <Title>Partner Details</Title>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={{
                id: partner!.id,
                name: partner!.name,
                alias: partner!.alias,
                account_email: partner!.account_email,
                opco_ids: readonly ? [] : assignedOPCOs!.map(x => x.id),
                pai_contingent: partner!.pai_contingent,
              }}
              validationSchema={validationSchema}
              onSubmit={async (data, { setSubmitting }) => {
                data.opco_ids = currentOPCOs.map((x) => x.id) as string[];
                setSubmitting(true);
                try {
                  await updatePartner(data);
                } catch (err: any) {
                  setMsg(extractErrorMessage(err));
                  setError(true);
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="name"
                        name="name"
                        placeholder="Name"
                        variant={variant}
                        readonly={readonly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="alias"
                        name="alias"
                        placeholder="Alias"
                        variant={variant}
                        readonly={true}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="end">
                      <Grid item xs={2}>
                        <FieldFW
                          type="number"
                          required
                          id="pai_contingent"
                          name="pai_contingent"
                          placeholder="PAI Contingent"
                          variant={variant}
                          readonly={readonly}
                        />
                      </Grid>
                      <Grid item xs={5} marginLeft="1em">
                        {partner!.pai_contingent - brands!.reduce((sum, brand) => sum += brand.pai_contingent, 0)} out of {partner!.pai_contingent} PAIs are available
                      </Grid>
                    </Grid>
                    {!readonly && (
                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            Update Partner Details
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => setOpenConfirm(true)}
                          >
                            Delete Partner
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid item lg={3} md={5} sm={5} xs={12}>
          <Paper className={classes.paper}>
            <Title>Logo</Title>
            <EditLogo
              id={partner?.id as string}
              type={LogoType.PARTNER}
              setError={(msg) => {
                setMsg(msg);
                setError(true);
              }}
              logoSrc={partner?.logo as string}
            />
          </Paper>
        </Grid>
        {role === "vodafone-admin" && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>OPCO Management</Title>
              <OPCOAvailableList
                opcos={availableOPCOs}
                setCurrentOPCOs={setCurrentOPCOs}
                setAvailableOPCOs={setAvailableOPCOs}
              />

              <OPCOChipList
                opcos={currentOPCOs}
                setCurrentOPCOs={setCurrentOPCOs}
                setAvailableOPCOs={setAvailableOPCOs}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Brands</Title>
            
            {(role === "partner-manager" || role === "vodafone-admin") && (
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddBrand(true)}
                >
                  Add Brand
                </Button>
              </div>
            )}
            
            <BrandsTable
              data={brands!}
              partnerAlias={partner!.alias}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
            />
          </Paper>
        </Grid>

        {(role === "partner-manager" || role === "vodafone-admin") && ( 
          <AddBrand 
            partnerId={partnerId}
            createBrand={createBrand}
            open={openAddBrand}
            setOpen={setOpenAddBrand}
            setErrorMsg={(msg) => {
              setMsg(msg);
              setError(true); 
            }}
          />
        )}

        {role === "vodafone-admin" ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Users</Title>
              <Button onClick={() => manageUsers(partner!.alias)}>
                Manage User Permissions
              </Button>
            </Paper>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <AlertDialog
        resourceName="this Partner"
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleYes={handleDelete}
      />
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
