/**
 * @file OPCO.tsx
 * @description OPCO page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Grid, Paper, Button } from "@mui/material";
import { Storage } from "aws-amplify";
import opcoService, { OPCOType } from "../services/opcoService";
import partnerService, { PartnerType } from "../services/partnerService";
import aggregatorService, {
  AggregatorType,
} from "../services/aggregatorService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Formik } from "formik";
import FieldFW from "../components/common/templates/forms/FieldFW";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Title from "../components/common/titles/Title";
import OnLoadWithNav from "../components/common/templates/WithNav/OnLoadWithNav";
import OnErrorWithNav from "../components/common/templates/WithNav/OnErrorWithNav";
import WithNav from "../components/common/templates/WithNav";
import useStyles from "../components/common/templates/style";
import PartnersTable from "../components/Partners/PartnersTable";
import { useUserContext } from "../context/UserContext";
import { validationSchema } from "../components/OPCOs/common/validationSchema";
import AggregatorsTable from "../components/Aggregators/AggregatorsTable";
import { useEffect, useState } from "react";
import EditLogo, { LogoType } from "../components/common/Logo/EditLogo";
import AlertDialog from "../components/common/templates/feedback/AlertDialog";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { isAggregatorsFeatureEnabled } from "../utils/featureToggle";
import { extractErrorMessage } from "../utils/utils";
import { invalidateOpcoQueries } from "../utils/invalidateQueries";

/**
 * Props
 * @typedef {{opcoId: string}} Props
 */
interface Props {
  opcoId?: string;
}
/**
 * Renders OPCO page
 * @param props component props @see Props
 * @returns {React.Component} OPCO page
 */
export default function OPCO(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { opcoId: opcoIdParam } = useParams<{ opcoId: string }>();
  const { user } = useUserContext();
  const { role } = user;
  const [signedURL, setSignedURL] = useState("");
  const [msg, setMsg] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const variant = role === "vodafone-admin" ? "outlined" : "standard";
  const readonly = role === "vodafone-admin" ? false : true;

  let opcoId: string;
  if (!props?.opcoId) {
    opcoId = opcoIdParam as string;
  } else {
    opcoId = props.opcoId as string;
  }

  const {
    data: opco,
    isLoading: loadingOPCO,
    error: opcoError,
  } = useQuery<OPCOType>(["opco", opcoId], () => opcoService.getSingle(opcoId));

  const {
    data: partners,
    isLoading: loadingPartners,
    error: partnersError,
  } = useQuery<PartnerType[]>(["assignedPartners", opcoId], () => partnerService.getAll(opcoId));

  const {
    data: aggregators,
    isLoading: loadingAggregators,
    error: aggregatorsError,
  } = useQuery<AggregatorType[]>(["assignedAggregators", opcoId], () => aggregatorService.getAll(opcoId));

  const queryClient = useQueryClient();

  const { mutateAsync: updateOPCO } = useMutation(opcoService.update, {
    onSuccess: () => {
      setMsg("OPCO updated!");
      setSuccess(true);
      invalidateOpcoQueries(queryClient);
    }
  });

  const { mutateAsync: deleteOPCO } = useMutation(opcoService.delete, {
    onSuccess: () => {
      setMsg("OPCO deleted!");
      setSuccess(true);
      invalidateOpcoQueries(queryClient);
      navigate("..", {relative: "path"});
    }
  });

  const manageUsers = (alias: string) => {
    navigate(location.pathname + "/users", {
      state: { opcoAlias: alias },
    });
  };
  
  useEffect(() => {
    async function getImgURL() {
      try {
        if (opco?.logo) {
          const res = await Storage.get(opco?.logo as string);
          setSignedURL(res);
        } else {
          const res = await Storage.get("default/logo/default-logo.png");
          setSignedURL(res);
        }
      } catch (e) {
        return <OnErrorWithNav error={e} />;
      }
    }
    getImgURL();
  });

  const handleDelete = () => {
    setOpenConfirm(false);
    deleteOPCO(opcoId).catch((err) => {
      setMsg(extractErrorMessage(err));
      setError(true);
    });
  };

  const isLoading: boolean = loadingOPCO || loadingPartners || loadingAggregators;
  const renderError = opcoError || partnersError || aggregatorsError;

  if (isLoading) return <OnLoadWithNav />;
  if (renderError) return <OnErrorWithNav error={renderError} />;

  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>{opco?.name as string}</Title>
          </Paper>
        </Grid>
        <Grid item lg={9} md={7} sm={7} xs={12}>
          <Paper className={classes.paper}>
            <Title>OPCO Details</Title>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={{
                id: opco?.id as string,
                logo: opco?.logo as string,
                name: opco?.name as string,
                alias: opco?.alias as string,
                market: opco?.market as string,
                account_email: opco?.account_email as string,
              }}
              validationSchema={validationSchema}
              onSubmit={async (opco, { setSubmitting }) => {
                setSubmitting(true);
                try {
                  await updateOPCO(opco);  
                } catch (err: any) {
                  setMsg(extractErrorMessage(err));
                  setError(true);
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="name"
                        name="name"
                        placeholder="Name"
                        variant={variant}
                        readonly={readonly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="alias"
                        name="alias"
                        placeholder="Alias"
                        variant={variant}
                        readonly={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldFW
                        type="text"
                        required
                        id="market"
                        name="market"
                        placeholder="Market"
                        variant={variant}
                        readonly={readonly}
                      />
                    </Grid>
                    {!readonly && (
                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            Update OPCO Details
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => setOpenConfirm(true)}
                          >
                            Delete OPCO
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
        <Grid item lg={3} md={5} sm={5} xs={12}>
          <Paper className={classes.paper}>
            <Title>Logo</Title>
            <EditLogo
              id={opco?.id as string}
              type={LogoType.OPCO}
              setError={(msg) => {
                setMsg(msg);
                setError(true);
              }}
              logoSrc={opco?.logo as string}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>Assigned Partners</Title>
            {role === "vodafone-admin" ? (
              <Button
                color="primary"
                style={{ justifyContent: "flex-start" }}
                onClick={() => {
                  navigate(location.pathname + "/partners");
                }}
              >
                Manage Partners
              </Button>
            ) : (
              <div></div>
            )}
            <PartnersTable
              data={partners!}
              setSuccessMessage={(msg) => {
                setMsg(msg);
                setSuccess(true);
              }}
              setErrorMessage={(msg) => {
                setMsg(msg);
                setError(true);
              }}
              shouldNotAllowDelete={true}
            />
          </Paper>
        </Grid>
        {isAggregatorsFeatureEnabled() &&
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Assigned Aggregators</Title>
              {role === "vodafone-admin" ? (
                <Button
                  color="primary"
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    navigate(location.pathname + "/aggregators");
                  }}
                >
                  Manage Aggregators
                </Button>
              ) : (
                <div></div>
              )}
              <AggregatorsTable data={aggregators} />
            </Paper>
          </Grid>}
        {role === "vodafone-admin" ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Users</Title>
              <Button onClick={() => manageUsers(opco?.alias as string)}>
                Manage User Permissions
              </Button>
            </Paper>
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
      <AlertDialog
        resourceName="this OPCO"
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleYes={handleDelete}
      />
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </WithNav>
  );
}
