/**
 * @file Dashboard.tsx Dashboard page
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Grid, Paper } from "@mui/material";
import Title from "../components/common/titles/Title";
import WithNav from "../components/common/templates/WithNav";
import useStyles from "../components/common/templates/style";
import { getDashboardPageTitle } from "../utils/featureToggle";

/**
 * Renders Dashboard page
 * @returns {React.Component} Dashboard page
 */
export default function Dashboard() {
  const classes = useStyles();
  return (
    <WithNav>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <Title>{getDashboardPageTitle()}</Title>
          </Paper>
        </Grid>
      </Grid>
    </WithNav>
  );
}
