/**
 * @file styles.ts
 * @description Defines common CSS styles for ./nav
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  active: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default useStyles;
